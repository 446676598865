import React from 'react'
import { Hero, Areas, Programas, Reporte, Servicios } from '../components'

const Inicio = () => {
  return (
    <div className='lg:mt-10 mt-24'>
      <Hero />
      <Programas />
      <Servicios />
      <Areas />
      <Reporte />
    </div>
  )
}

export default Inicio