import React from 'react';
import { Header, ButtonOutline } from '../components';

const Reporte = () => {
  return (
    <div className='mt-24 flex flex-col items-center'>
      <div className="w-72">
        <Header texto={'Reporte Ciudadano'} />
      </div>
      <textarea 
        type="text" 
        id="reporte" 
        name='reporte' 
        placeholder='Escribe aquí tu reporte' 
        className='w-full bg-slate-50 mt-10 mb-10 leading-10 rounded-2xl indent-2.5 border-2 border-ultralightPurple'
        rows={10}
      />
      <ButtonOutline texto={'Enviar'}/>   
    </div>
  )
}

export default Reporte