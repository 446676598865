import React from 'react';
import { Header, Button } from '.';
import Lottie from 'react-lottie-player';
import { serviciosInicio } from '../constants';
import '../App.css';

const Servicios = () => {
  return (
    <div className='lg:min-h-screen mb-20 lg:mb-0'>
      <div className='bg-blue-50 flex flex-col rounded-3xl items-center'>
        <div className='mt-10'>
          <Header texto={"Servicios"} />
        </div>
        <div className='mt-5'>
          <h1 className='lg:text-5xl md:text-4xl text-3xl font-bold lg:mt-0 mt-10 lg:text-left text-center mb-10 md:mb-0'>
            <span className='text-lightBlue'>Servicios</span> en Línea
          </h1>
        </div>
        <div className="flex lg:px-2 px-5 flex-wrap gap-12 lg:gap-0 items-center justify-center">
          {serviciosInicio.map((servicio) => (
            <div key={servicio.id} className="flex flex-col text-center w-1/4 justify-center items-center basis-full md:basis-1/3 lg:basis-1/4">
              <div className={`rounded-fulL h-60 w-60 flex justify-center items-center image-cropper bg-${servicio.color}`} style={{backgroundColor: servicio.color}}>
                <Lottie
                  loop
                  animationData={servicio.lottie}
                  play
                  className='lottie-servicios'
                />  
              </div>
              <h2 className='font-bold mt-10 text-lg'>{servicio.title}</h2> 
              <p>{servicio.descripcion}</p>
            </div>
          ))}
        </div>
        <div className='mb-20 mt-20'>
          <Button texto={"Conoce Más"} referencia={'/servicios'}/>
        </div>
      </div>
 
    </div>
  )
}

export default Servicios