import React from 'react';
import { AiOutlineClockCircle, AiOutlinePhone } from 'react-icons/ai';
import { TiLocationOutline } from 'react-icons/ti';
import { areas } from '../constants';

const Direccion = () => {

  return (
    <div className='flex gap-10 flex-wrap justify-center'>
        <div className='bg-white w-50 rounded-2xl shadow-2xl px-6 py-4 w-80'>
            <div className=''>
                <div className='rounded-2xl'>
                    {areas[0].ubicacion}
                </div>
                <h1 className='font-bold leading-1 header mt-5'>
                    {areas[0].title}
                </h1>
                <div className='flex flex-col gap-2 mt-5'>
                    <div className='flex gap-2 items-center'>
                        <AiOutlineClockCircle className='text-md basis-1/6'/>
                        <p className='text-xs basis-5/6' >{areas[0].horario}</p>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <TiLocationOutline className='text-lg basis-1/6'/>
                        <p className='text-xs basis-5/6'>{areas[0].direccion}</p>                  
                    </div>
                    <div className='flex gap-2 items-center'>
                        <AiOutlinePhone className='text-lg basis-1/6'/>
                        <a className='text-xs basis-5/6' href={`tel:${areas[0].telefono}`}>{areas[0].telefono}</a>                  
                    </div>
                </div> 
            </div>
        </div>
        <div className='bg-white w-50 rounded-2xl shadow-2xl px-6 py-4 w-80'>
            <div className=''>
                <div className='rounded-2xl'>
                    {areas[1].ubicacion}
                </div>
                <h1 className='font-bold leading-1 header mt-5'>
                    {areas[1].title}
                </h1>
                <div className='flex flex-col gap-2 mt-5'>
                    <div className='flex gap-2 items-center'>
                        <AiOutlineClockCircle className='text-md basis-1/6'/>
                        <p className='text-xs basis-5/6' >{areas[1].horario}</p>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <TiLocationOutline className='text-lg basis-1/6'/>
                        <p className='text-xs basis-5/6'>{areas[1].direccion}</p>                  
                    </div>
                    <div className='flex gap-2 items-center'>
                        <AiOutlinePhone className='text-lg basis-1/6'/>
                        <a className='text-xs basis-5/6' href={`tel:${areas[1].telefono}`}>{areas[1].telefono}</a>                  
                    </div>
                </div> 
            </div>
        </div>
        <div className='bg-white w-50 rounded-2xl shadow-2xl px-6 py-4 w-80'>
            <div className=''>
                <div className='rounded-2xl'>
                    {areas[2].ubicacion}
                </div>
                <h1 className='font-bold leading-1 header mt-5'>
                    {areas[2].title}
                </h1>
                <div className='flex flex-col gap-2 mt-5'>
                    <div className='flex gap-2 items-center'>
                        <AiOutlineClockCircle className='text-md basis-1/6'/>
                        <p className='text-xs basis-5/6' >{areas[2].horario}</p>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <TiLocationOutline className='text-lg basis-1/6'/>
                        <p className='text-xs basis-5/6'>{areas[2].direccion}</p>                  
                    </div>
                    <div className='flex gap-2 items-center'>
                        <AiOutlinePhone className='text-lg basis-1/6'/>
                        <a className='text-xs basis-5/6' href={`tel:${areas[2].telefono}`}>{areas[2].telefono}</a>                  
                    </div>
                </div> 
            </div>
        </div>
    </div>

  )
}

export default Direccion