import React from 'react'
import { logo } from '../assets';
import { areas } from '../constants';
import { AiOutlineClockCircle, AiOutlinePhone } from 'react-icons/ai';
import { TiLocationOutline } from 'react-icons/ti';

const Contacto = () => {
  return (
    <div className='pt-20 flex gap-4 flex-wrap justify-center'>
      {areas.map((area, index)=> (
        <div className='bg-white rounded-2xl shadow-2xl px-6 py-4 w-72' key={index} >
          <div>
             <div className='flex justify-center items-center gap-2'>
                <img src={logo} alt="logo" className='w-16 h-16'/>
                <h1 className='font-bold leading-1 header mt-2 text-sm md:text-base'>
                    {area.title}
                </h1>
             </div>
             <div className='flex flex-col gap-2 mt-2'>
                 <div className='flex gap-2 items-center'>
                     <AiOutlineClockCircle className='text-md basis-1/6'/>
                     <p className='text-xs basis-5/6' >{area.horario}</p>
                 </div>
                 <div className='flex gap-2 items-center'>
                     <TiLocationOutline className='text-lg basis-1/6'/>
                     <p className='text-xs basis-5/6'>{area.direccion}</p>                  
                 </div>
                 <div className='flex gap-2 items-center'>
                     <AiOutlinePhone className='text-lg basis-1/6'/>
                     <a className='text-xs basis-5/6' href={`tel:${area.telefono}`}>{area.telefono}</a>                  
                 </div>
             </div> 
          </div>
        </div>
      ))}
    </div>
  )
}

export default Contacto