import React from 'react'

const Button = ({texto,referencia}) => {
  return (
    <a href={referencia} className='px-10 py-4 border-2 border-secondary text-lg text-gray-800 rounded-t-full rounded-br-full rounded-bl-md hover:bg-secondary hover:transition-all hover:duration-500 hover:text-white cursor-pointer'>
        {texto}
    </a>
  )
}

export default Button