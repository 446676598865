import React from 'react';
import { serviciosEnLinea } from '../constants';

const Servicios = () => {
  return (
    <div className='pt-20 flex flex-wrap gap-5 justify-center'>
      {serviciosEnLinea.map((servicio)=> (
        <a className='bg-secondary pt-5 pb-2 px-5 rounded-2xl w-32 h-40 lg:w-52 lg:h-auto hover:bg-ultralightPurple hover:transition-all' key={servicio.id} href={servicio.href}>
          <h2 className='text-white font-bold text-center lg:text-base text-sm'>{servicio.title}</h2>
          <img src={servicio.icon} alt={servicio.title} className="w-20 lg:w-auto"/>
        </a>
      ))}
    </div>
  )
}

export default Servicios