import {servicioUno, servicioDos, servicioTres, servicioCuatro, twitter, instagram, facebook, sbecas, salimentarios, sasilo, sestanciadiurna, sprotesismamarias, slentes, ssilladeruedas, sauditivos, sotros, pninos, padolecentes, padultos, pmujeres, padultosmayores, pdiscapacidad, splaticas, dsipinna, dproteccion, dprogramas, datencion, dderechos, sescolares } from '../assets';

// ------------------- NAVBAR --------------------
export const navLinks = [
  {
    id: "inicio",
    title: "Inicio",
  },
  {
    id: "servicios",
    title: "Servicios",
  },
  {
    id: "spot",
    title: "Jóvenes",
  },
  {
    id: "programas",
    title: "Programas",
  },  
  {
    id: "contacto",
    title: "Contacto",
  },  
  {
    id: "reporte",
    title: "Reporte Ciudadano",
  },
];

// ------------------- SERVICIOS --------------------
export const serviciosInicio = [
  {
    id: "educacion",
    title: "Educación",
    descripcion: "Becas para la educación, desayunos escolares, ...",
    lottie: servicioDos,
    color:"#EFC289",
  },
  {
    id: "asistencia_social",
    title: "Asistencia Social",
    descripcion: "Asilo Miguel Hidalgo, Estancia Diurna del Adulto Mayor",
    lottie: servicioUno,
    color: "#F2F5F0",
  },
  {
    id: "salud",
    title: "Salud",
    descripcion: "Prótesis mamarias, consultas médicas y odontológicas, UBR ... ",
    lottie: servicioTres,
    color: "#c6bbdd",
  },
  {
    id: "programas",
    title: "Programas",
    descripcion: "Explora todos nuestros servicios en línea",
    lottie: servicioCuatro,
    color: "#8e6fbd",
  },  
];

// ------------------- FOOTER --------------------
export const footerLinks = [
  {
    title: "Menú Rápido",
    links: [
      {
        name: "Inicio",
        link: "/inicio",
      },
      {
        name: "Servicios",
        link: "/servicios",
      },
      {
        name: "Programas",
        link: "/programas",
      },
      // {
      //   name: "Noticias",
      //   link: "/noticias",
      // },
      {
        name: "Reporte",
        link: "/reporte",
      },
    ],
  },
  {
    title: "Acerca de",
    links: [
      {
        name: "Aviso de Privacidad",
        link: "/avisodeprivacidad",
      },
      {
        name: "Transparencia ",
        link: "https://www.morelia.gob.mx/transparencia/",
      },
      {
        name: "Contacto",
        link: "/contacto",
      },
    ],
  },
  {
    title: "Morelia Brilla",
    links: [
      {
        name: "Informes",
        link: "https://www.morelia.gob.mx/alfonso-martinez-presenta-y-entrega-1er-informe-de-gobierno/",
      },
      {
        name: "Gobierno Municipal",
        link: "https://www.morelia.gob.mx/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/moreliadif/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/DIFMorelia",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://twitter.com/dif_morelia",
  },
];


// ------------------- CONTACTO --------------------
export const areas = [
  {
    id: "direccion-general",
    title: "Dirección General DIF Morelia.", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    ubicacion: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.5995634132505!2d-101.18305768498645!3d19.687082137595006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0de6dc0c427d%3A0x7c3f424b1770d2fc!2sDIF%20Morelia!5e0!3m2!1ses-419!2smx!4v1663934212671!5m2!1ses-419!2smx" style={{width: 270, height: 200}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='rounded-2xl border-primary' samesite='Strict' title='21'></iframe>,
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "secretaria-tecnica-general",
    title: "Secretaría Técnica General", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    ubicacion: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.5995634132505!2d-101.18305768498645!3d19.687082137595006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0de6dc0c427d%3A0x7c3f424b1770d2fc!2sDIF%20Morelia!5e0!3m2!1ses-419!2smx!4v1663934212671!5m2!1ses-419!2smx" style={{width: 270, height: 200}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='rounded-2xl border-primary' samesite='Strict' title='21'></iframe>,
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "secretaria-tecnica",
    title: "Secretaría Técnica SIPINNA de Morelia", 
    direccion: "Rafaela López Aguado 282, La Estrella, 58150 Morelia, Mich.",
    ubicacion: <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15026.738392043297!2d-101.2014393!3d19.683457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc944f8b32bc3aed9!2sSecretar%C3%ADa%20Ejecutiva%20del%20SIPINNA%20del%20Estado%20de%20Michoac%C3%A1n!5e0!3m2!1ses-419!2smx!4v1672167393496!5m2!1ses-419!2smx" style={{width: 270, height: 200}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='rounded-2xl border-primary' samesite='Strict' title='21'></iframe>,
    telefono: "4432982140",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-vinculacion",
    title: "Departamento de Vinculación con la Sociedad Civil", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-comunicacion",
    title: "Departamento de Comunicación Social, Eventos y Protocolos", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "direccion-proteccion-social",
    title: "Dirección de Protección Social", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    ubicacion: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.5995634132505!2d-101.18305768498645!3d19.687082137595006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0de6dc0c427d%3A0x7c3f424b1770d2fc!2sDIF%20Morelia!5e0!3m2!1ses-419!2smx!4v1663934212671!5m2!1ses-419!2smx" style={{width: 270, height: 200}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='rounded-2xl border-primary' samesite='Strict' title='21'></iframe>,
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },
  {
    id: "departamento-de-centros-de-asistencia",
    title: "Dirección de Centros de Asistencia Social", 
    ubicacion: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.5995634132505!2d-101.18305768498645!3d19.687082137595006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0de6dc0c427d%3A0x7c3f424b1770d2fc!2sDIF%20Morelia!5e0!3m2!1ses-419!2smx!4v1663934212671!5m2!1ses-419!2smx" style={{width: 270, height: 200}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='rounded-2xl border-primary' samesite='Strict' title='21'></iframe>,
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "direccion-de-programas",
    title: "Dirección de Programas", 
    direccion: "Rafaela López Aguado 282, La Estrella, 58150 Morelia, Mich.",
    ubicacion: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.5995634132505!2d-101.18305768498645!3d19.687082137595006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0de6dc0c427d%3A0x7c3f424b1770d2fc!2sDIF%20Morelia!5e0!3m2!1ses-419!2smx!4v1663934212671!5m2!1ses-419!2smx" style={{width: 270, height: 200}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='rounded-2xl border-primary' samesite='Strict' title='21'></iframe>,
    telefono: "4432982140",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "direccion-de-desarrollo-integral",
    title: "Dirección de Desarrollo Integral de la Niña y del Niño", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    ubicacion: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.5995634132505!2d-101.18305768498645!3d19.687082137595006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0de6dc0c427d%3A0x7c3f424b1770d2fc!2sDIF%20Morelia!5e0!3m2!1ses-419!2smx!4v1663934212671!5m2!1ses-419!2smx" style={{width: 270, height: 200}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='rounded-2xl border-primary' samesite='Strict' title='21'></iframe>,
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-regulacion",
    title: "Departamento de Regulacion", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-atencion-a-menores",
    title: "Departamento de Atención a Menores y Adolescentes en Riesgo", 
    direccion: "Mirasol 263, Las Margaritas, 58160 Morelia, Mich.",
    telefono: "4433268344",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-cultura",
    title: "Departamento de Cultura de la Discapacidad", 
    direccion: "Paseo de la Republica s/n, Francisco Zarco, 58147 Morelia, Mich.",
    telefono: "4433340555",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-asilo",
    title: "Departamento de Asilo de Ancianos Miguel Hidalgo", 
    direccion: "Av. Acueducto 1697, Matamoros, 58218 Morelia, Mich.",
    telefono: "4433212266",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-atencion-al-autismo",
    title: "Departamento de Atención al Autismo", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-programas-alimentarios",
    title: "Departamento de Programas Alimentarios", 
    direccion: "Rafaela López Aguado 282, La Estrella, 58150 Morelia, Mich.",
    telefono: "4432982140",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },
  {
    id: "departamento-de-programas-comunitarios",
    title: "Departamento de Programas Comunitarios", 
    direccion: "Rafaela López Aguado 282, La Estrella, 58150 Morelia, Mich.",
    telefono: "4432982140",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-atencion-social",
    title: "Departamento de Atención Social", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-centros-de-atencion-infantil",
    title: "Departamento de Centros de Atención Infantil Comunitario", 
    direccion: "Vicente Barroso de La Escayola 135, La Estrella, 58070 Morelia, Mich.",
    telefono: "4431334000",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },  
  {
    id: "departamento-de-ludotecas-municipales",
    title: "Departamento de Ludotecas Municipales", 
    direccion: "Rafael Carrillo 138, Centro, 58000 Morelia, Mich.",
    telefono: "4433139127",
    horario: "Lunes a Viernes 8:30 - 16:30",
  },
];


// ------------------- SERVICIOS EN LÍNEA --------------------
export const serviciosEnLinea = [
  // ------------------- SOLICITUDES
  {
    id: "desayunos-escolares",
    title: "Desayunos Escolares",
    icon: sescolares,
    href: "/servicios/desayunos",
  },  
  {
    id: "lentes",
    title: "Lentes",
    icon: slentes,
    href: "/servicios/lentes",
  },  
  {
    id: "silla-de-ruedas",
    title: "Silla de Ruedas",
    icon: ssilladeruedas,
    href: "/servicios/sillas",
  },  
  {
    id: "aparatos-auditivos",
    title: "Aparatos Auditivos",
    icon: sauditivos,
    href: "/servicios/auditivos",
  },    
  {
    id: "programas-alimentarios",
    title: "Programas alimentarios",
    icon: salimentarios,
    href: "/servicios/alimentarios",
  },    
  {
    id: "protesis-mamarias",
    title: "Protesis mamarias",
    icon: sprotesismamarias,
    href: "/servicios/protesis",
  },  
  {
    id: "platicas-escolares",
    title: "Pláticas Escolares",
    icon: splaticas,
    href: "/servicios/platicas",
  },  
  {
    id: "asilo-mh",
    title: "Asilo Miguel Hidalgo",
    icon: sasilo,
    href: "/servicios/asilo",
  },  
  {
    id: "estancia-da",
    title: "Estancia diurna",
    icon: sestanciadiurna,
    href: "/servicios/estancia",
  },  
  {
    id: "becas-camar",
    title: "Becas CAMAR",
    icon: sbecas,
    href: "/servicios/becas",
  },  
  {
    id: "otros",
    title: "Otros",
    icon: sotros,
    href: "/servicios/otros",
  },
// ------------------- AGENDA
  // {
  //   id: "ludoteca",
  //   title: "Ludoteca",
  //   icon: sludoteca,
  //   href: "",
  // },  
  // {
  //   id: "consulta-medica",
  //   title: "Consulta Médica",
  //   icon: smedico,
  //   href: "",
  // },  
  // {
  //   id: "asesoria-psicologica",
  //   title: "Asesoría psicológica",
  //   icon: spsicologicos,
  //   href: "",
  // },  
  // {
  //   id: "asesoria-juridica",
  //   title: "Asesoría jurídica",
  //   icon: sjuridicos,
  //   href: "",
  // },    
  // {
  //   id: "ubr",
  //   title: "UBR",
  //   icon: subr,
  //   href: "",
  // },
];

// ------------------- PROGRAMAS --------------------
export const programas = [
  {
    id: "ninos",
    title: "Niños",
    icon: pninos,
    href: "/programas/ninos",
  },    
  {
    id: "adolescentes",
    title: "Adolescentes",
    icon: padolecentes,
    href: "/programas/adolescentes",
  },   
  {
    id: "adultos",
    title: "Adultos",
    icon: padultos,
    href: "/programas/adultos",
  },    
  {
    id: "mujeres",
    title: "Mujeres",
    icon: pmujeres,
    href: "/programas/mujeres",
  },    
  {
    id: "adultos-mayores",
    title: "Adultos mayores",
    icon: padultosmayores,
    href: "/programas/mayores",
  },  
  {
    id: "personas-con-discapacidad",
    title: "Personas con discapacidad",
    icon: pdiscapacidad,
    href: "/programas/discapacidad",
  }, 
]

// ------------------- PROGRAMAS DIRECCIONES --------------------
export const direccionesNinos = [
  {
    id: "sipinna",
    title: "SIPINNA",
    icon: dsipinna,
    href: "/programas/ninos/sipinna",
  },    
  {
    id: "proteccion",
    title: "Direccion de Proteccion Social",
    icon: dproteccion,
    href: "/programas/ninos/proteccion",
  },   
  {
    id: "programas",
    title: "Dirección de Programas",
    icon: dprogramas,
    href: "/programas/ninos/programas",
  },    
  {
    id: "atencion",
    title: "Dirección de Centros de Atención",
    icon: datencion,
    href: "/programas/ninos/atencion",
  },    
  {
    id: "derechosniños",
    title: "Dirección de D.I de la Niña y Niño",
    icon: dderechos,
    href: "/programas/ninos/derechos",
  },  
]

export const direccionesAdolescentes = [
  {
    id: "sipinna",
    title: "SIPINNA",
    icon: dsipinna,
    href: "/programas/adolescentes/sipinna",
  },    
  {
    id: "proteccion",
    title: "Direccion de Proteccion Social",
    icon: dproteccion,
    href: "/programas/adolescentes/proteccion",
  },   
  {
    id: "programas",
    title: "Dirección de Programas",
    icon: dprogramas,
    href: "/programas/adolescentes/programas",
  },    
  {
    id: "atencion",
    title: "Dirección de Centros de Atención",
    icon: datencion,
    href: "/programas/adolescentes/atencion",
  },    
]

export const direccionesAdultos = [
  {
    id: "proteccion",
    title: "Direccion de Proteccion Social",
    icon: dproteccion,
    href: "/programas/adultos/proteccion",
  },   
  {
    id: "programas",
    title: "Dirección de Programas",
    icon: dprogramas,
    href: "/programas/adultos/programas",
  },    
  {
    id: "atencion",
    title: "Dirección de Centros de Atención",
    icon: datencion,
    href: "/programas/adultos/atencion",
  },    
]

export const direccionesMujeres = [  
  {
    id: "proteccion",
    title: "Direccion de Proteccion Social",
    icon: dproteccion,
    href: "/programas/mujeres/proteccion",
  },   
  {
    id: "programas",
    title: "Dirección de Programas",
    icon: dprogramas,
    href: "/programas/mujeres/programas",
  },    
  {
    id: "atencion",
    title: "Dirección de Centros de Atención",
    icon: datencion,
    href: "/programas/mujeres/atencion",
  },    
]

export const direccionesMayores = [    
  {
    id: "proteccion",
    title: "Direccion de Proteccion Social",
    icon: dproteccion,
    href: "/programas/mayores/proteccion",
  },   
  {
    id: "programas",
    title: "Dirección de Programas",
    icon: dprogramas,
    href: "/programas/mayores/programas",
  },    
  {
    id: "atencion",
    title: "Dirección de Centros de Atención",
    icon: datencion,
    href: "/programas/mayores/atencion",
  },    
]

export const direccionesDiscapacidad = [
  {
    id: "sipinna",
    title: "SIPINNA",
    icon: dsipinna,
    href: "/programas/discapacidad/sipinna",
  },    
  {
    id: "proteccion",
    title: "Direccion de Proteccion Social",
    icon: dproteccion,
    href: "/programas/discapacidad/proteccion",
  },   
  {
    id: "programas",
    title: "Dirección de Programas",
    icon: dprogramas,
    href: "/programas/discapacidad/programas",
  },    
  {
    id: "atencion",
    title: "Dirección de Centros de Atención",
    icon: datencion,
    href: "/programas/discapacidad/atencion",
  },    
]