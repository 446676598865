import React from 'react';
import { ButtonOutline, Header } from '.';
import { Direccion } from '.';


const Areas = () => {
  return (
    <div className='lg:min-h-screen mb-20 lg:mb-0'>
      <div className='flex flex-col justify-center items-center'>
        <div className='w-56'>
          <Header texto={"Áreas"} />
        </div>
        <div className='mt-10'>
          <h1 className='font-bold lg:text-5xl'>
           <span className='text-lightBlue'>Áreas</span>
          </h1>
        </div>
        <div>
          <Direccion />
        </div>
        <div className='mt-16'>
          <ButtonOutline texto={"Ver Todas"} referencia={'/contacto'}/>
        </div>
      </div>
    </div>
  )
}

export default Areas