import React from 'react'

const Header = ({texto}) => {
  return (
    <div>
      <div className='text-md text-orange-400 bg-orange-200 rounded-full font-semibold px-6 py-2 ml-auto mr-auto text-center uppercase tracking-widest'>
          {texto}
      </div>
    </div>
  )
}

export default Header