import React from 'react';
import { loading } from '../assets';
import Lottie from 'react-lottie-player';

const Load = () => {
  return (
    <div>
        <Lottie
        loop
        animationData={loading}
        play
        className='pt-[10rem] mr-auto ml-auto w-80'
        />
    </div>
  )
}

export default Load