import React from 'react';
import { Header, Button } from '.';
import Lottie from 'react-lottie-player';
import { reporte } from '../assets';

const Reporte = () => {
  return (
    <div className="py-10">
      <div className='flex flex-col justify-center items-center'>
        <div className='w-72'>
          <Header texto={"Reporte Ciudadano"} />
        </div>
        <div className='mt-10 flex gap-8 bg-blue-50 justify-center items-center rounded-3xl flex-wrap'>
          
          <div className='md:px-10 px-6 rounded-tr-3xl rounded-bl-3xl lg:basis-1/3' style={{backgroundColor: "#CFCFD0"}}>
            <Lottie
              loop
              animationData={reporte}
              play
              className='lottie-reporte'
            />  
          </div>

          <div className='lg:basis-2/5  flex flex-col justify-center px-5'>
            <h1 className='lg:text-5xl md:text-4xl text-2xl font-bold lg:mt-0 lg:text-left text-center'>
              Reporte <span className='text-lightBlue'>Ciudadano</span>
            </h1>
            <p className='text-gray-600 text-basis leading-10 mt-5 text-center lg:text-left'>
              Caminamos juntos para que Morelia brille. Envíanos tu reporte y sugerencias en nuestro centro de atención virtual.
            </p>
            <div className='mt-5 flex justify-center mb-10'>
              <Button texto={"Hazlo aquí"} referencia={'/reporte'}/>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Reporte