import React from 'react';
import { Button, Header } from '.';
import { programas } from '../assets';
import Lottie from 'react-lottie-player';

const Programas = () => {
  return (
    <div className='lg:min-h-screen mb-20 lg:mb-0'>
      <div className='w-56 ml-auto mr-auto'>
        <Header texto={"Programas"} />
      </div>
      <div className='flex flex-wrap lg:flex-nowrap'>
        <div className='flex flex-col justify-center items-center lg:items-start'>
          <h1 className='lg:text-5xl md:text-4xl text-3xl font-bold lg:mt-0 mt-10 lg:text-left text-center'>
            <span className='text-lightBlue'>Conoce</span> nuestros Servicios y Programas
          </h1>
          <p className='text-gray-600 text-lg leading-10 mt-10 text-center lg:text-left'>
            Clasificados y segmentados acorde a tu grupo social, te guiamos en un proceso dinámico y divertido a través de los servicios y programas que ofrece el DIF Morelia
          </p>
          <div className="mt-16 mb-10 lg:mb-0">
            <Button texto={"Conoce Más"} referencia={'/programas'}/>
          </div>
        </div>
        <div>
          <Lottie
            loop
            animationData={programas}
            play
            className='lottie-programas'
          />  
        </div>
      </div>
    </div>
  )
}

export default Programas