import React from 'react';
import { programas } from '../constants';

const Programas = () => {
  return (
    <div>
      <h1 className='text-gray-500 text-center text-xl pt-20 mb-5 lg:text-3xl'>Descubre los programas que el <span className='font-bold text-secondary'>DIF Morelia</span> tiene para ti</h1>
      <div className='flex flex-wrap gap-5 justify-center'>
        {programas.map((servicio)=> (
          <a href={servicio.href} className='bg-secondary pt-5 px-5 rounded-2xl w-32 md:w-36 lg:w-52 lg:h-auto hover:bg-ultralightPurple hover:transition-all' key={servicio.id}>
            <h2 className='text-white font-bold text-center lg:text-base text-xs'>{servicio.title}</h2>
            <img src={servicio.icon} alt={servicio.title} className="w-20 lg:w-auto"/>
          </a>
        ))}
      </div>
    </div>
  )
}

export default Programas