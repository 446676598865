import React from 'react';
import Lottie from 'react-lottie-player'
import { home } from '../assets'
import "../App.css";
import { ButtonOutline, Button } from '.';

const Hero = () => {
  return (
    <div className='flex lg:min-h-screen items-center flex-col-reverse lg:flex-row mb-20 lg:mb-0'>

      <div className='flex flex-col justify-center lg:pr-10'>
        <h1 className='lg:text-6xl md:text-5xl text-3xl leading-[3rem] md:leading-[4rem] lg:leading-[4.5rem] font-bold text-center lg:text-left'>
          Atención <span className='text-lightBlue'>Integral </span>
          <span className='text-lightBlue'>Asistencia</span> Social
        </h1> 
        <p className='text-gray-600 text-lg leading-10 md:mt-10 mt-5 text-center lg:text-left'>
          Promovemos la paz y la integración familiar a través de una atención inmediata, digna, humana y eficaz enfocada a grupos de atención prioritaria de Morelia
        </p>
        <div className='md:gap-10 mt-10 flex lg:justify-start justify-center flex-wrap'>
          <div><Button texto={"Contáctanos"} referencia={'/contacto'} /></div>
          <div className='mt-12 md:mt-0'><ButtonOutline texto={"Conoce Más"} referencia={'/programas'}/></div>
        </div>
      </div>

      <div>
        <Lottie
        loop
        animationData={home}
        play
        className='lottie-home lottie-none'
        />
      </div>

    </div>
  )
}

export default Hero