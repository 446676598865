import React from 'react'
import { logomorelia } from '../assets'

const Aviso = () => {
  return (
    <div className='pt-20'>
      <div className='w-36 mr-auto mb-5 ml-auto'><img src={logomorelia} alt="logomorelia" className=''/></div>
      <div className='text-center'>
        <h1 className='text-2xl uppercase font-semibold text-secondary tracking-wider'>Aviso de Privacidad Integral</h1>
        <h2 className='text-xl text-primary tracking-wide'>SISTEMA MUNICIPAL PARA EL DESARROLLO INTEGRAL DE LA FAMILIA DE MORELIA</h2>
      </div>
      <div className='text-justify'>
        <p>
          <br /> <b>Denominación del responsable</b>:  El H. Ayuntamiento de Morelia, a través del Sistema Municipal para el Desarrollo Integral de la Familia de Morelia, es la instancia encargada de proteger los datos personales que por cualquier medio físico o electrónico recabe, por lo tanto, es responsable del tratamiento de los datos personales que nos proporcione para el desahogo y atención de cualquier comunicación o solicitud personal, telefónica, por escrito, correo electrónico y en línea, reciba el Ayuntamiento.
          <br /><br /> Todos ellos serán protegidos conforme a lo dispuesto por el Artículo 6°, apartado A, fracción II, y el 16 segundo párrafo de la Constitución Política de los Estados Unidos Mexicanos; por la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados; por la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de Michoacán y la demás normatividad que resulte aplicable.
          <br /><br /> <b>Domicilio de la instancia responsable</b>: Calle Vicente Barroso de la Escayola #135, col. La Estrella, C.P. 58070, tel. 4431134000, horario de servicio lunes a viernes de 08:30 horas a 16:30 horas, correo electrónico <span className='underline text-secondary'>dirección.dif@morelia.gob.mx</span>, ubicado entre las calles de Ireriticateme y Cutzi.
          <br /><br /> <b>Finalidad del tratamiento</b>: Sus datos personales identificativos serán utilizados para registrar y dar seguimiento a los servicios otorgados que brinda el H. Ayuntamiento de Morelia a cualquier persona. Además de establecer comunicación directa con los ciudadanos en caso de ser necesario, para la prestación de los servicios requeridos, como podrían ser:
        </p>
        <br />
        <ul className='list-disc list-outside ml-5'>
          <li className='mb-2'>Difusión de los programas, eventos, campañas de protección de los derechos y spots informativos con los servicios que se ofrecen en el SMDIF, creación de campañas y para el registro de participación de los usuarios en los eventos;</li>
          <li className='mb-2'>Generar vínculos con personas de la sociedad civil, la ciudadanía, instituciones gubernamentales de los tres niveles de gobierno; organismos no gubernamentales e instituciones de Asistencia Privada, entre otros;</li>
          <li className='mb-2'>Realizar y promover estudios e investigaciones de manera autónoma y en coordinación con otras instituciones con la finalidad de fortalecer las acciones tendientes a la atención, defensa, protección de los derechos de niñas, niños y adolescentes y prestar servicios de capacitación en espacios educativos y comunitarios sobre temas de protección de los derechos de Niñas, Niños y Adolescentes;</li>
          <li className='mb-2'>Representar legalmente los intereses de las niñas, niños, adolescentes, personas adultas mayores y personas con discapacidad ante autoridades judiciales y administrativas/ Recibir y atender todo reporte violatorio de derechos o circunstancias que pongan en peligro la seguridad, integridad y dignidad de las niñas, niños, adolescentes y personas adultas mayores;</li>
          <li className='mb-2'>Proporcionar hospedaje, alimentación, atención médica y dignificación humana a personas adultas mayores en estado de abandono y vulnerabilidad;</li>
          <li className='mb-2'>Brindar a los adultos mayores atención de calidad y la oportunidad de contar con un espacio favorable fuera de su domicilio, donde puedan realizar actividades creativas y recreativas que les permita tener una mejor calidad de vida;</li>
          <li className='mb-2'>Para la participación en los programas de becas académicas, en el programa de Fortalecimiento de los Servicios de Educación Especial PFSEE, y en el programa de estrategias para la defensa de los derechos de los niños;</li>
          <li className='mb-2'>Fomentar la cultura de apoyo a personas con discapacidad; brindar servicios integrales mediante la orientación de actividades y acciones que promuevan la inclusión y accesibilidad de las personas con discapacidad del Municipio;</li>
          <li className='mb-2'>Brindar atención y tratamiento de personas con trastorno del espectro autista; impulsar la plena integración e inclusión a la sociedad de las personas con la condición del espectro mediante la protección de sus derechos y necesidades fundamentales;</li>
          <li className='mb-2'>Contribuir al desarrollo y bienestar social mediante acciones de calidad que mejoren la alimentación y nutrición de las personas en estado vulnerable; </li>
          <li className='mb-2'>Promover el fortalecimiento del desarrollo y la cohesión de la comunidad de la población del Municipio, atendiendo a personas en situación de vulnerabilidad;</li>
          <li className='mb-2'>Brindar asistencia social, médica, psicológica y dental inmediata a la población vulnerable, a niñas, niños, adolescentes, personas adultas mayores y personas con discapacidad, a través de apoyos y servicios integrales; </li>
          <li className='mb-2'>Prestar servicios educativos en las comunidades del municipio a niños y niñas de 3 a 6 años y;</li>
          <li>Brindar servicios lúdico-recreativos a niños, niñas y padres de familia que asistan a las ludotecas.</li>
        </ul>
        <p>
          <br /> Contamos con cámaras de video-vigilancia dentro de las instalaciones como parte de las medidas de seguridad tomadas por esta dependencia con la finalidad de brindar seguridad a los usuarios, visitantes y servidores públicos, además de control de ingreso y salida de quienes acuden a estas instalaciones.
          <br /><br /> Los datos personales se mantendrán con fines estadísticos, con el objeto de contar con información que permita realizar indicadores de gestión, informes de labores, así como analizar la viabilidad de realizar adecuaciones y mejoras. Es importante señalar que las estadísticas que se generen, no vincularán la información general con datos que hagan identificables a los titulares.
          <br /><br /><b>Datos personales sometidos a tratamiento</b>: Los datos personales enlistados a continuación, podrán ser solicitados por el Sistema Municipal para el Desarrollo Integral de la Familia de Morelia, de forma directa o indirecta, y únicamente serán utilizados para el cumplimiento de los objetivos, facultades, atribuciones y obligaciones de la misma, a través de los documentos idóneos que los contengan: 
        </p>
        <br />
        <ul className='list-disc list-outside ml-5'>
          <li className='mb-2'>Nombre</li>
          <li className='mb-2'>Domicilio</li>
          <li className='mb-2'>Salario</li> 
          <li className='mb-2'>Identificación Oficial</li>
          <li className='mb-2'>Clave Única de Registro de Población</li>
          <li className='mb-2'>Datos Migratorios</li>
          <li className='mb-2'>Edad</li>
          <li className='mb-2'>Estado Civil</li>
          <li className='mb-2'>Fecha de Nacimiento</li>
          <li className='mb-2'>Firma autógrafa o Huella Dactilar</li>
          <li className='mb-2'>Información familiar: Número de miembros, nombre, edad, parentesco y ocupación</li>
          <li className='mb-2'>Información Académica</li>
          <li className='mb-2'>Ocupación</li>
          <li className='mb-2'>Número telefónico</li>
          <li className='mb-2'>Información relacionada a sus bienes muebles e inmiebles</li>
          <li className='mb-2'>Lugar de nacimiento</li>
          <li className='mb-2'>Nombre de 3 familiares o amigos que deven brindar los siguientes datos: nombre completo, parentesco, domicilio, teléfono particular, celular y laboral</li>
          <li className='mb-2'>Parentescos</li>
          <li className='mb-2'>Sexo</li>
          <li className='mb-2'>Registro Federal de Contribuyentes</li>
          <li className='mb-2'>Seguridad Social</li>
          <li className='mb-2'>Ingresos</li>
          <li className='mb-2'>Correo electrónico</li>
          <li className='mb-2'>Egresos</li>
          <li className='mb-2'>Tipo de asentamiento donde vive</li>
          <li className='mb-2'>Estado y municipo de nacimiento</li>
          <li className='mb-2'>Imagen</li>
          <li>Imagen en video</li>
        </ul>
        <br />
        <p>
          <b>Datos personales de menores de edad que podrían ser solicitados:</b>
        </p>
        <br />
        <ul className='list-disc list-outside ml-5'>
          <li className='mb-2'>Nombre</li>
          <li className='mb-2'>Sexo</li>
          <li className='mb-2'>Edad</li>
          <li className='mb-2'>Domicilio</li>
          <li className='mb-2'>Lugar de nacimiento</li>
          <li className='mb-2'>Fecha de nacimienti</li>
          <li className='mb-2'>Nacionalidad</li>
          <li className='mb-2'>Clave única de registro de población</li>
          <li className='mb-2'>Información académica</li>
          <li className='mb-2'>Parentescos</li>
          <li>Número telefónico (adolescentes)</li>
        </ul>
        <br />
        <p>
          <b>Datos sensibles que podrían ser recabados</b>: Además de los datos personales En el caso de los datos personales y sensibles de menores de edad, losmecanismos que se tienen implementados para recabar el consentimiento de la persona que ejerce la patria potestad, o en su caso, del tutor o representante legal, están sujetos a las reglas de representación dispuestas en el Código Familiar para el Estado de Michoacán de Ocampo y supletoriamente las disposiciones establecidas en el Código Civil Federal.
          mencionados, la Dirección General del Sistema Municipal para el Desarrollo Integral de la Familia de Morelia, utiliza los siguientes datos personales considerados como sensibles, que requieren de especial protección, así como el consentimiento expreso del Titular de los mismos para su tratamiento:
        </p>
        <br />
        <ul className='list-disc list-outside ml-5'>
          <li className='mb-2'>Creencias religiosas</li>
          <li className='mb-2'>Creencias filosóficas</li>
          <li className='mb-2'>Creencias morales</li>
          <li className='mb-2'>Estado de salud</li>
          <li className='mb-2'>Historial clínico</li>
          <li className='mb-2'>Discapcidad y tipo de discapacidad</li>
          <li className='mb-2'>Enfermedades</li>
          <li className='mb-2'>Información relacionada con cuestiones de car+acter psicológico/psiquiátrico</li>
          <li className='mb-2'>Tipo de sangre</li>
          <li className='mb-2'>Pertenencia a grupos u organizaciones de la sociedad civil</li>
          <li className='mb-2'>Peso</li>
          <li className='mb-2'>Altura</li>
          <li className='mb-2'>Color de piel, iris, color de cabello</li>
          <li className='mb-2'>Señas particulares</li>
          <li className='mb-2'>Pasatiempos</li>
          <li className='mb-2'>Aficiones</li>
          <li className='mb-2'>Origen Racial o Étnico</li>
          <li className='mb-2'>Preferencia sexual</li>
          <li className='mb-2'>Hábitos sexuales</li>
          <li className='mb-2'>Deportes que practica</li>
          <li>Juegos de su interés</li>
        </ul>
        <br />
        <p>
          <b>Finalidad del tratamiento que requiera el consentimiento del titular</b>: Los datos personales sensibles, se recaban con los siguientes objetivos y fines: otorgar servicios asistenciales de calidad, creación de campañas y difusión de las acciones y programas del SMDIF Morelia, así como, la concentración de datos para registro de participación en eventos del SMDIF Morelia; para dar cumplimiento a las instrucciones 
          <br /><br /> Judiciales, para proporcionar una atención médica adecuada y dar seguimiento a los tratamientos de usuarios, será recabado el consentimiento para su tratamiento mediante la aceptación al aviso de privacidad integral que se pondrá a disposición del titular al momento de la obtención de los mismos. 
          <br /><br /> Los datos sensibles de menores de edad, serán recabados mediante el consentimiento por escrito al aviso de privacidad integral que realice el padre o tutor, el cual le será presentado de forma directa al momento de recabar los mismos. 
          <br /><br /> En el caso de imágenes o videos captados en eventos propios de la actividad institucional en las que aparezcan menores de edad, personas en estado de interdicción y/o personas con discapacidad, para hacer uso de la imagen y que sean publicados en medios de comunicación con el objeto de promover y difundir las acciones de gobierno, será recabado el consentimiento explícito y por escrito del padre o tutor de conformidad con el principio de información mediante la aceptación del aviso de privacidad integral al momento de recabar los mismos, en todo momento se evitara dar a conocer datos que puedan hacer identificable a la persona que aparece en las capturas gráficas o cualquier otro dato que vulnere la privacidad, se promoverán medidas de seudonimización, anonimizarían, disociación y técnicas que ayuden a difuminar la imagen frontal de dichos individuos, y en ningún caso se realizara un aprovechamiento patrimonial de la imagen.
          <br /><br /> Fundamento legal que faculta el tratamiento: Los datos personales y sensibles en posesión de esta Dirección General del Sistema Municipal para el Desarrollo Integral de la Familia de Morelia son los necesarios y proporcionales para el ejercicio de las atribuciones y objetivos establecidos en:
        </p>
      </div>
      <p>
        <br />-	Constitución Política de los Estados Unidos Mexicanos
        <br />-	Ley General de Prestación de Servicios para la Atención, Cuidado y Desarrollo Integral Infantil Artículos 1°, 5°, del 9° al 13; 34, 35, 38, 50, 52, 53 y 56;
        <br />-	Ley General de los Derechos de Niñas, Niños y Adolescentes artículos 26 fracción IV, 29, 98, 99, 111, 119 y 120 fracción III;
        <br />-	Ley de Asistencia Social artículos 3, 4, 7, 12 y 22 inciso f);
        <br />-	Ley General para la Atención y Protección de personas con la Condición del Espectro Autista, artículos 2°, 6° fracción IX; 10, 11 y 16;
        <br />-	Reglamento de la Ley de General para la Atención y Protección de personas con la Condición del Espectro Autista artículos 2° fracción I, 3° segundo párrafo; 10, 11, 12 párrafo primero; 13 fracción II; 
        <br />-	Ley General de Salud.
        <br />-	Ley General de Educación artículos 1, 2, 4, 7, 11, 15 y 25;
        <br />-	Ley de los Derechos de las Personas Adultas Mayores;
        <br />-	NOM-004-SSA3-2012, Del expediente clínico.
        <br />-	Ley de Asistencia Social del Estado de Michoacán;
        <br />-	Código de Justicia Administrativa del Estado de Michoacán de Ocampo
        <br />-	Código Familiar para el Estado de Michoacán de Ocampo. 
        <br />-	Ley Orgánica Municipal del Estado de Michoacán de Ocampo
        <br />-	Ley de Asociaciones Religiosas y Culto Público
        <br />-	Ley de Transparencia, Acceso a la Información y Protección de Datos Personales del Estado de Michoacán
        <br />-	Ley para la Inclusión de Personas con Discapacidad del Estado de Michoacán;
        <br />-	Ley de Protección Integral a las Personas Adultas Mayores del Estado de Michoacán de Ocampo en su totalidad;
        <br />-	Lineamientos de Integración, Organización y funcionamiento de los Sistemas Municipales de Protección Integral e Niñas, Niños y Adolescentes en su totalidad;
        <br />-	Programa de Desayunos Escolares y sus reglas de operación en su totalidad;
        <br />-	Programa de Proyectos Productivos y sus reglas de operación en su totalidad;
        <br />-	Programa de Capacitación, Encuentro y Desarrollo y sus reglas de operación en su totalidad;
        <br />-	Bando de Gobierno del Municipio de Morelia en sus artículos 60 y 61
        <br />-	Reglamento de Organización de la Administración Pública del Municipio de Morelia, Michoacán, en sus artículos 52, 53 y 54. 
        <br />-	Reglamento de Acceso a la Información y Protección de Datos Personales del Municipio, en su totalidad.
        <br />-	Ley de Ingresos del Municipio de Morelia
        <br />-	Reglamento para la Protección e Integración Social de las Personas Adultas Mayores y Personas con Capacidades Diferentes del Municipio de Morelia 6, 7, 11, 17 al 20; 50, 51, 52 y 54;
        <br />-	Reglamento Interior del Sistema Municipal para el Desarrollo Integral del Sistema Municipal para el Desarrollo Integral de la Familia de Morelia artículos 2°, 4°, 5°, 7°, 29, 34 fracciones VII y IX; 35, 39, 40, 42, 43, 44, 46, 47, 49, 50 fracciones II, VI, VII y IX; del 51 al 55; 57 fracción VII; 58, del 60, al 66; 67 fracciones IV, V, VI, IX,XI, XII, XIII Y XIV; 69, 72, 73, 76, 78, 82, 83, 84, 87, 89, 90, 92 y el capítulo XI;
        <br />-	Acuerdo por el que se crea el Sistema de Protección Integral e Niñas, Niños y Adolescentes del Municipio de Morelia en su totalidad;
        <br />-	Acuerdo mediante el cual se Establecen los Lineamientos y Criterios para la Elaboración, Revisión, Registro y Seguimiento de Contratos y Convenios; 
        <br />-	Reglamento Municipal de Protección Civil, en su totalidad.
        <br />-	Reglamento Interno del Archivo Histórico Municipal de Morelia
        <br />-	Reglamento de Establecimientos Mercantiles, Industriales y de Servicios en el Municipio de Morelia
      </p>
      <br />
      <p>
        <b>Transferencia de datos personales</b>: Se podrá transferir el nombre, domicilio, teléfono particular, edad, estado de salud, y enfermedades a proveedores de servicios de salud particulares autorizados por el Ayuntamiento de Morelia para brindar atención y asistencia médica por concepto de apoyos especiales como: vales de medicamento cambiables en farmacias autorizadas, prótesis mamarias, auxiliares auditivos, aparatos funcionales y apoyos de sillas de ruedas, además de los mencionados no será transferido ningún otro dato personal salvo aquéllos que no requieran el consentimiento de los titulares, en términos de lo previsto en el artículo 18 de la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de Michoacán, siempre que dicha transferencia esté prevista en la ley, sea legalmente exigida para la investigación y persecución de los delitos, así como la procuración o administración de justicia y sea requerida por autoridad competente mediante mandamiento escrito; Adicionalmente, solo se transfieren los datos personales de las áreas operativas a las administrativas, con el objeto de comprobación de acciones.
        <br /><br /><b>Mecanismos, medios y procedimientos disponibles para ejercer los derechos ARCO, para que el titular pueda manifestar la negativa a su tratamiento</b>: Usted podrá presentar una solicitud para el ejercicio de sus derechos ARCO, en términos de lo establecido en los artículos 49, 50, 51 y 52 de la Ley General de Protección de Datos Personales en Posesión de Sujetos y 44, 45, 47 y 48 de la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de Michoacán de Ocampo; a través de la Plataforma Nacional de Transparencia [http://www.plataformadetransparencia.org.mx] o bien, ante la Unidad de Transparencia del Ayuntamiento de Morelia, denominada: 
      </p>
      <br />
      <h3 className='text-center uppercase text-lg text-primary font-semibold'>
        Dirección de transparencia y Acceso a la información
      </h3>
      <h4>
        <br /> Ubicación: Calle Uruguay, número 497, Colonia Las Américas, C.P. 58270
        <br /> Correos electrónicos: clasificación.ditai@morelia.gob.mx y transparenciamorelia@morelia.gob.mx
        <br /> Número telefónico: 443-312-3641
      </h4>
      <br />
      <p className='font-bold'>
        Requisitos de la solicitud para el ejercicio de los derechos ARCO (artículo 52 de la Ley General)
      </p>
      <br />
      <ul className='list-decimal list-outside ml-5'>
        <li className='mb-2'>Nombre del titular, domicilio o cualquier otro medio para recibir notificaciones; </li>
        <li className='mb-2'>Exhibir los documentos que acrediten la identidad del titular y, en su caso, la personalidad e identidad de su representante; </li>
        <li className='mb-2'>Precisar el área que trata los datos personales; </li>
        <li className='mb-2'>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos ARCO; </li>
        <li className='mb-2'>La descripción del derecho ARCO que se pretende ejercer, o bien, lo que solicita el titular, </li>
        <li className='mb-2'>Cualquier otro elemento o documento que facilite la localización de los datos personales,</li>
        <li className='mb-2'>En caso de solicitar el acceso a datos personales, se deberá señalar la modalidad en la que prefiere que éstos se reproduzcan;</li>
        <li className='mb-2'>Tratándose de una solicitud de cancelación, señalar las causas que lo motiven a solicitar la supresión de sus datos; </li>
        <li>Para la oposición, manifestar las causas legítimas o la situación específica que lo llevan a requerir el cese del tratamiento, así como el daño o perjuicio que le causaría la persistencia del tratamiento.</li>
      </ul>
      <br />
      <p>
        En caso de que usted considere que su derecho a la protección de datos personales ha sido vulnerado, le asiste el derecho de acudir ante el Instituto Michoacano de Transparencia, Acceso a la Información y Protección de Datos Personales del Estado de Michoacán [http://imaip.org.mx], donde podrá presentar su inconformidad.
        <br /><br /> <b>Medios a través de los cuales el responsable comunicará a los titulares los cambios al aviso de privacidad:</b> Se hará de su conocimiento a través del portal de transparencia de la página web oficial del H. Ayuntamiento de Morelia: [https://www.morelia.gob.mx/transparencia/] 
      </p>
    </div>
  )
}

export default Aviso