import React, {Suspense} from 'react';
import { createRoot } from 'react-dom/client';
import { Load } from './pages';
import './index.css'
const App = React.lazy(()=> import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Suspense fallback={<Load/>}>
        <App />
    </Suspense>
);